















































































































import {Component, Prop, Watch, Mixins} from 'vue-property-decorator'
import {MixinRouteMatch} from '@/components/mixins/MixinRouteMatch'
import FilterToggle from '@/components/FilterToggle.vue'
import FilterCategory from '@/components/filters/FilterCategory.vue'
import {Category} from '@/model/resource/Category'
import {CategoryCollection} from '@/model/collection/CategoryCollection'
import {ListCategorySchema} from '@/schema/resource/Category/ListCategorySchema'
import {ExportCategorySchema} from '@/schema/resource/Category/ExportCategorySchema'

@Component({
  components: {FilterToggle, FilterCategory},
})
export default class ListCategoryView extends Mixins(MixinRouteMatch) {
  schema = new ListCategorySchema()
  collection = new CategoryCollection()
  filterOpen = false

  async created() {
    if (this.hasQueryParams) {
      this.updateObjectFromRoute(this.collection)
    }
    await this.$await.run('hardQuery', () => this.collection.queryAsPage())
  }

  @Watch('collection', {deep: true})
  collectionEvent() {
    this.updateRouteFromObject(this.collection)
  }

  goToPersistView(item: Category) {
    this.$nav.pushByName('editCategory', item.$id)
  }

  async removeItem(item: Category) {
    await this.$dialog.remove(item)
    await item.removeCategory()
    await this.collection.queryAsPage()
  }

  async downloadXlsx() {
    const {ascending, orderBy, page, limit, ...params} = this.collection.params

    const coll = new CategoryCollection().clearFilters().addFilter(params)

    await coll.listExportCategory()
    this.$xlsx.downloadFromSchema(coll.items, new ExportCategorySchema())
  }
}
